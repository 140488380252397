<template>
  <v-container :fluid="true" class="synapsa-container">
    <v-row align="center" justify="center" class="mx-0">
      <v-col cols="12" class="px-2 py-2 synapsa-tabs">
        <v-btn
          v-for="tab in tabs"
          v-permission="tab.permissions"
          :key="tab.id"
          :text="!tab.ids.includes(activeTab)"
          :color="tab.ids.includes(activeTab) ? 'primary' : ''"
          :outlined="tab.ids.includes(activeTab)"
          small
          class="text-capitalize synapsa-tab"
          :class="tab.ids.includes(activeTab) ? 'elevation-1' : ''"
          @click="to(tab)"
        >
          {{ $t(tab.name) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import permission from "@/directive/permission/permission.js";
export default {
  directives: { permission },
  data() {
    return {
      activeTab: "miners",
      tabs: [
        {
          id: "miners",
          ids: ["miners"],
          name: "settings.miners",
          route: "/settings/show/miners",
          permissions: ["view miner"],
        },
        {
          id: "firewalls",
          ids: ["firewalls"],
          name: "settings.firewalls",
          route: "/settings/show/firewalls",
          permissions: ["view firewall"],
        },
        {
          id: "centralManagement",
          ids: ["centralManagement"],
          name: "settings.centralManagement",
          route: "/settings/show/central_management",
          permissions: ["view firewall"],
        },
        {
          id: "firewallGroups",
          ids: ["firewallGroups"],
          name: "settings.firewallGroups",
          route: "/settings/show/firewall_groups",
          permissions: ["view firewallgroup"],
        },
        {
          id: "parser",
          ids: ["parser"],
          name: "settings.parser",
          route: "/settings/show/parser",
          permissions: ["view parser"],
        },
        {
          id: "api",
          ids: [
            "api",
            "apiPaloAlto",
            "apiFortinet",
            "apiCheckpoint",
            "apiSophos",
            "apiCustom",
          ],
          name: "settings.api",
          route: "/settings/show/api",
          permissions: ["view api"],
        },
        {
          id: "listener",
          ids: ["listener"],
          name: "settings.listener",
          route: "/settings/show/listener",
          permissions: ["view listener"],
        },
        {
          id: "integration",
          ids: ["integration"],
          name: "settings.integration",
          route: "/settings/show/integration",
          permissions: ["view integration"],
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.activeTab = newValue.name;
      },
    },
  },
  methods: {
    to(tab) {
      this.$router.push(tab.route);
      this.activeTab = tab.id;
    },
  },
};
</script>
